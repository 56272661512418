import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { screen } from '@styles/mixins/breakpoints';
import { fonts, getTypographyStyles } from '@styles/variables';

const StyledTextHeading = styled.h1`
  max-width: 998px;
  padding: 0 32px 48px;
  margin: auto;
  ${getTypographyStyles.heading(false, fonts.fontSecondaryBold)};
  text-align: center;

  ${screen.tablet(css`
    padding-bottom: 46px;
    font-size: 24px;
  `)}

  ${screen.l(css`
    padding: 0 16px 46px;
  `)}

  ${({ isInvisibleHeading }) => isInvisibleHeading
    && css`
      position: absolute;
      overflow: hidden;
      width: 1px;
      height: 1px;
      clip: rect(1px, 1px, 1px, 1px);
    `}
`;

const TextHeading = ({ title, isInvisibleHeading }) => (
  <StyledTextHeading isInvisibleHeading={isInvisibleHeading}>
    {title}
  </StyledTextHeading>
);

TextHeading.defaultProps = {
  isInvisibleHeading: false,
};

TextHeading.propTypes = {
  isInvisibleHeading: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default TextHeading;
