import React from 'react';
import styled, { css } from 'styled-components';
import { Helmet } from 'react-helmet';
import { Field } from 'formik';
import { screen } from '@styles/mixins/breakpoints';
import { colors, fonts, getTypographyStyles } from '@styles/variables';

import TextHeading from '@components/TextHeading/TextHeading';

const StyledEnquiries = styled.div`
  padding: 48px 0 120px;

  ${screen.tablet(css`
    padding-top: 46px;
  `)} h3 {
    padding-bottom: 88px;

    ${screen.tablet(css`
      padding-bottom: 46px;
    `)}
  }
`;

const StyledEnquiriesContainer = styled.div`
  display: flex;
  max-width: 710px;
  flex-wrap: wrap;
  padding: 0 32px;
  margin: 0 auto;
  font: 16px/24px ${fonts.fontPrimaryRegular}, Arial, sans-serif;
  letter-spacing: 0.12px;

  ${screen.sm(css`
    padding: 0 16px;
  `)}
`;

const StyledEnquiriesItem = styled.p`
  width: 100%;
  ${getTypographyStyles.body(true)}

  &:first-child {
    margin-bottom: 12px;
  }
`;

const StyledEnquiriesItemLink = styled.a`
  color: ${colors.colorCeruleanBlue};
  font-family: ${fonts.fontPrimaryRegular};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    color: black;
    text-decoration: underline;
  }
`;

const StyledSeparator = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 16px;
  margin-bottom: 32px;
  background: ${colors.colorMutedWhite};
`;

const PressEnquiries = () => {
  const descriptionText = 'To get in touch with the Depop PR team, please email press@depop.com'.slice(
    0,
    160,
  );

  return (
    <>
      <Helmet>
        <meta name="description" content={descriptionText} />
        <meta itemProp="description" content={descriptionText} />
        <meta property="og:description" content={descriptionText} />
        <meta property="twitter:description" content={descriptionText} />
      </Helmet>
      <StyledEnquiries>
        <TextHeading title="Press enquiries" />
        <StyledEnquiriesContainer>
          <StyledEnquiriesItem>
            To get in touch with the Depop PR team, please email us at
            {' '}
            <StyledEnquiriesItemLink href="mailto:press@depop.com">
              press@depop.com
            </StyledEnquiriesItemLink>
          </StyledEnquiriesItem>
          <StyledSeparator />
          <StyledEnquiriesItem>
            If you’re having an issue with your Depop account or would like to
            reach our Customer Support team,
            {' '}
            <StyledEnquiriesItemLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://depophelp.zendesk.com/hc/en-gb/requests/new"
            >
              get in touch here
            </StyledEnquiriesItemLink>
          </StyledEnquiriesItem>
        </StyledEnquiriesContainer>
      </StyledEnquiries>
    </>
  );
};

export default PressEnquiries;
